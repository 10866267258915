<template>
    <Card class="order_detail_container">
        <div slot="title">订单详情</div>
        <div class="form_item">
            <div class="form_label">预约单号</div>
            {{ formData.order_no || '-' }}
        </div>
        <div class="form_item">
            <div class="form_label">订单状态</div>
            {{ { 0: '进行中', 1: '已完成' }[formData.status] }}
        </div>
        <div class="form_item">
            <div class="form_label align_self_start">预约服务</div>
            {{ (serviceList.find(info => info.id == formData.sid) || {}).title || formData.sid }}
        </div>
        <div class="form_item">
            <div class="form_label">预约人</div>
            {{ formData.username || '-' }}
        </div>
        <div class="form_item">
            <div class="form_label">预约人电话</div>
            {{ formData.mobile || '-' }}
        </div>
        <div v-if="!!formData.third_order && formData.sid == 17" class="form_item">
            <div class="form_label">灯光检测订单号</div>
            {{ formData.third_order || '-' }}
        </div>
        <div class="form_item">
            <div class="form_label">预约地址</div>
            {{ formData.address || '-' }}
        </div>
        <div class="form_item">
            <div class="form_label">预约日期</div>
            <!-- <MyDate v-model="formData.stime"></MyDate> -->
            {{ formData.stime }}
        </div>

        <template v-if="formData.sid == 18">
            <div class="form_item">
                <div class="form_label align_self_start">灯高地高度</div>
                {{ formData.Agl || '' }}m
            </div>
            <div class="form_item">
                <div class="form_label align_self_start">灯的数量</div>
                {{ formData.num || '' }}
            </div>
            <div class="form_item">
                <div class="form_label align_self_start">是否有旧灯拆卸</div>
                {{ formData.detach ? '有旧灯' : '无旧灯' }}
            </div>
            <div class="form_item">
                <div class="form_label align_self_start">灯具订单号</div>
                {{ formData.third_order || '' }}
            </div>
            <div class="form_item">
                <div class="form_label align_self_start">备注</div>
                {{ formData.orderNote || '' }}
            </div>
        </template>

        <div class="form_item" v-if="(formData.mid || 0) > 0" v-btn="'ASSIGN_SERVICE_PROVIDER'">
            <div class="form_label align_self_start">此订单已分配给服务商</div>
            {{ (spList.find(info => info.id == formData.mid) || {}).merchant || formData.mid }}
        </div>
        <div class="form_item" v-if="(formData.checkid || 0) > 0" v-btn="'ASSIGN_EMPLOYEE'">
            <div class="form_label align_self_start">此订单已分配给检测人员</div>
            {{ (employeeList.find(info => info.id == formData.checkid) || {}).nickname || formData.checkid }}
        </div>
        <div class="mt_50 flex align_center justify_center form_item btns">
            <template v-if="formData.status == 0">
                <i-button v-if="(formData.mid || 0) <= 0" v-btn="'ASSIGN_SERVICE_PROVIDER'" type="success" @click="onAssignOrder">分配服务商</i-button>
                <i-button v-if="(formData.checkid || 0) <= 0" v-btn="'ASSIGN_EMPLOYEE'" type="success" @click="onAssignEmployee">分配人员</i-button>
            </template>
            <i-button type="primary" ghost @click="onCloseEdit">取消</i-button>
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>

        <!--选择服务商-->
        <Modal :mask-closable="false" :closable="false" class="assign_order" v-model="assignOrder.show" title="分配服务商" width="650" :loading="ajaxLoading">
            <div class="form_item">
                <div class="form_label must_input">请选择服务商</div>
                <Select v-model="assignOrder.mid">
                    <Option v-for="info in spList" :key="info.id" :value="info.id">{{ info.merchant }}</Option>
                </Select>
            </div>
            <div slot="footer" class="relative width_100_percent flex align_center justify_center">
                <Button type="primary" ghost @click="onCancelAssign">取消</Button>
                <Button class="ml_100" type="primary" :loading="ajaxLoading" @click="onConfirmAssign">确定</Button>
            </div>
        </Modal>

        <!--选择人员-->
        <Modal :mask-closable="false" :closable="false" class="assign_order" v-model="assignEmployee.show" title="分配人员" width="650" :loading="ajaxLoading">
            <div class="form_item">
                <div class="form_label must_input">请选择人员</div>
                <Select v-model="assignEmployee.userId">
                    <Option v-for="info in employeeList" :key="info.id" :value="info.id">{{ info.nickname }}</Option>
                </Select>
            </div>
            <div slot="footer" class="relative width_100_percent flex align_center justify_center">
                <Button type="primary" ghost @click="onCancelAssignEmployee">取消</Button>
                <Button class="ml_100" type="primary" :loading="ajaxLoading" @click="onConfirmAssignEmployee">确定</Button>
            </div>
        </Modal>
    </Card>
</template>
<script>
import editor from '@/components/editor';
import MyDate from '@/components/myDate';
import { reqReservationView, reqServeList } from '@/lib/request/auth';
import { createId } from '@/lib/util';
import { groupIdDef, reqAccountByGroupId, reqAllocationC, reqAllocationM } from '@/lib/request/auth2';

export default {
    name: 'orderDetail',
    components: {
        editor,
        MyDate,
    },
    data() {
        return {
            isEdit: false,
            formData: {
                id: null,
                sid: null,
                username: null,
                mobile: null,
                order_no: null,
                stime: null,
                etime: null,
                status: null,
                address: null,
                remark: null,
                mid: null,
                checkid: null,
                Agl: null,
                num: null,
                detach: null,
                third_order: null,
                orderNote: null,
            },
            yuyueDate: { start: '', end: '' },
            spList: [], //服务商列表
            assignOrder: {
                show: false,
                mid: null,
            },
            employeeList: [], //人员列表
            assignEmployee: {
                show: false,
                userId: null,
            },
            serviceList: [],
        };
    },
    mounted() {
        this.routeIdInject();
        this.getServiceList();
        this.getServiceProviderList();
        this.getEmployeeList();
        this.getDetail();
    },
    methods: {
        //获取服务列表
        getServiceList() {
            reqServeList({
                pageNums: 1,
                pageSize: 200,
            }).then(res => {
                this.serviceList = res?.data?.list || [];
            });
        },
        //获取预约详情
        getDetail() {
            if (this.isEdit) {
                this.showAjaxLoading();
                reqReservationView({ id: this.formData.id })
                    .then(res => {
                        this.dataInjectFormData(res);
                        // if (this.formData.stime) {
                        //     // this.yuyueDate = [new Date(this.formData.stime * 1000).Format('yyyy-MM-dd HH:mm:ss'), new Date(this.formData.etime * 1000).Format('yyyy-MM-dd HH:mm:ss')];
                        //     this.yuyueDate = this.formData.stime;
                        // }
                    })
                    .finally(() => {
                        this.hideAjaxLoading();
                    });
            }
        },
        //取消
        onCloseEdit() {
            this.$router.back();
        },
        //确认
        onConfirmEdit() {
            let params = window.structuredClone(this.formData);

            let { yuyueDate, remark } = params || {};
            let { start, end } = yuyueDate;
            let vs = [
                { value: start, tip: '请选择预约开始时间' },
                { value: end, tip: '请选择预约结束时间' },
            ];
            hm.validateForm(vs)
                .then(() => {
                    console.log('onConfirmEdit', params);
                    this.fadeAlert('保存成功');
                    this.goUrl('/orderList');
                })
                .catch(msg => {
                    this.fadeWarning(msg);
                });
        },
        //分配服务商
        onAssignOrder() {
            this.assignOrder.mid = null;
            this.assignOrder.show = true;
        },
        getServiceProviderList() {
            reqAccountByGroupId(groupIdDef.sp).then(res => {
                this.spList = res?.data?.list || [];
            });
        },
        onCancelAssign() {
            this.assignOrder.show = false;
            setTimeout(() => {
                this.hideAjaxLoading();
            }, 500);
        },
        onConfirmAssign() {
            if (!!!this.assignOrder.mid) {
                this.fadeWarning('请选择服务商');
                return;
            }
            this.showAjaxLoading();
            reqAllocationM({
                id: this.formData.id,
                mid: this.assignOrder.mid,
            })
                .then(res => {
                    this.fadeAlert(res.errmsg);
                    this.onCancelAssign();
                    this.onCloseEdit();
                })
                .catch(() => {
                    this.hideAjaxLoading();
                });
        },
        //分配人员
        getEmployeeList() {
            reqAccountByGroupId(groupIdDef.qa).then(res => {
                this.employeeList = res?.data?.list || [];
            });
        },
        onAssignEmployee() {
            this.assignEmployee.userId = null;
            this.assignEmployee.show = true;
        },
        onCancelAssignEmployee() {
            this.assignEmployee.show = false;
            setTimeout(() => {
                this.hideAjaxLoading();
            }, 500);
        },
        onConfirmAssignEmployee() {
            if (!!!this.assignEmployee.userId) {
                this.fadeWarning('请选择人员');
                return;
            }
            this.showAjaxLoading();
            reqAllocationC({
                id: this.formData.id,
                userId: this.assignEmployee.userId,
            })
                .then(res => {
                    this.fadeAlert(res.errmsg);
                    this.onCancelAssignEmployee();
                    this.onCloseEdit();
                })
                .catch(() => {
                    this.hideAjaxLoading();
                });
        },
    },
};
</script>
<style lang="less" scoped>
.order_detail_container {
    .btns {
        gap: 0 50px;
    }
    .assign_order {
        .form_label {
            width: 150px;
        }
    }
}
</style>
